* {
  margin: 0px;
  padding: 0px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

fieldset {
  border: none;
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

ul,
ol,
li,
dd,
dt {
  list-style: none;
  margin: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

[role='button'] {
  cursor: pointer;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.fix {
  overflow: hidden;
}

.rpl {
  overflow: hidden;
  text-indent: -9999em;
  display: block;
}
.rpl a,
.rpl span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  font-family: 'Poppins', 'Arial', serif;
  font-size: 62.5%;
  color: #fff;
}
body.blocked {
  overflow: hidden;
}

.container {
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
}

.fnt-russo {
  font-family: 'Russo One', 'Arial', serif;
  font-weight: 400;
}

.btn {
  background-color: #ffb620;
  display: inline-block;
  border-radius: 80px;
  height: 53px;
  line-height: 53px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  padding: 0 40px;
}

.title-n1 {
  font-size: 46px;
  line-height: 46px;
  font-weight: 300;
}

.logo {
  background: url(../img/logo.png) no-repeat 0 0;
  width: 327px;
  height: 107px;
  margin-bottom: 22px;
}

#Header {
  background: url(../img/topo.png) no-repeat top right #000;
  padding-top: 105px;
  padding-bottom: 70px;
}
#Header .logo {
  margin-bottom: 22px;
}
#Header h2 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 400;
  margin-bottom: 24px;
}
#Header h3 {
  font-size: 46px;
  line-height: 55px;
  font-weight: 400;
  margin-bottom: 30px;
}
#Header p {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 20px;
}
#Header .btn {
  margin-top: 20px;
}

#ComoFunciona {
  background: rgb(10, 10, 28);
  background: linear-gradient(
    90deg,
    rgb(10, 10, 28) 0%,
    rgb(28, 45, 76) 55%,
    rgb(47, 24, 75) 80%,
    rgb(47, 24, 75) 100%
  );
  padding: 60px 0 67px 0;
  text-align: center;
}
#ComoFunciona header {
  padding-bottom: 30px;
}
#ComoFunciona header p {
  margin-bottom: 30px;
}
#ComoFunciona header .fnt-russo {
  margin-bottom: 30px;
}
#ComoFunciona p {
  font-size: 18px;
}
#ComoFunciona .columns {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}
#ComoFunciona .columns .box {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  padding: 40px 19px;
}
#ComoFunciona .columns .fnt-russo {
  height: 93px;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#ComoFunciona .columns p {
  font-size: 18px;
  line-height: 27px;
}
#ComoFunciona h4 {
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 40px;
}

#ContainerGames {
  background: rgb(50, 66, 94);
  background: radial-gradient(
    circle,
    rgb(50, 66, 94) 0%,
    rgb(50, 66, 94) 55%,
    rgb(44, 46, 72) 80%,
    rgb(67, 46, 93) 99%
  );
  padding: 60px 0 30px 0;
}
#ContainerGames header {
  text-align: center;
  padding-bottom: 50px;
}
#ContainerGames .container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
#ContainerGames .container .box {
  max-width: 612px;
  height: 273px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 12px;
  display: flex;
}
#ContainerGames .container .box .info {
  padding: 12px 51px 0 24px;
  font-size: 16px;
}
#ContainerGames .container .box .info .fnt-russo {
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 12px;
}
#ContainerGames .container .box .info.last {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
#ContainerGames .container .box .info.last .title-n1 {
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 28px;
}

#JogarComAmigos {
  background: url(../img/jogar-com-amigos.png) no-repeat top center #000;
  height: 500px;
  position: relative;
}
#JogarComAmigos::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
#JogarComAmigos .container {
  position: relative;
  z-index: 99;
  padding-top: 88px;
  padding-bottom: 54px;
}
#JogarComAmigos .title-n1 {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 24px;
}
#JogarComAmigos p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 33px;
}

#SectionMatchcoins {
  background: url(../img/crypto.png) no-repeat right top;
  height: 500px;
  position: relative;
}
#SectionMatchcoins::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgb(21, 19, 58) 40%,
    rgba(0, 0, 0, 0) 100%
  );
}
#SectionMatchcoins .container {
  position: relative;
  z-index: 99;
  padding-top: 88px;
  padding-bottom: 54px;
}
#SectionMatchcoins .title-n1 {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 24px;
}
#SectionMatchcoins p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 33px;
}

#CriarTorneio {
  background: rgb(50, 66, 94);
  background: linear-gradient(
    90deg,
    rgb(50, 66, 94) 0%,
    rgb(54, 57, 93) 31%,
    rgb(67, 47, 93) 61%,
    rgb(67, 46, 93) 99%
  );
  padding: 49px 0 59px;
}
#CriarTorneio .container {
  background: url(../img/criar-torneio.png) no-repeat 0 0;
  min-height: 392px;
}
#CriarTorneio .info {
  padding-left: 445px;
  padding-right: 100px;
  padding-top: 45px;
}
#CriarTorneio .title-n1 {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 24px;
}
#CriarTorneio p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 33px;
}

#BoxDescubra {
  background: rgb(28, 45, 76);
  background: linear-gradient(
    90deg,
    rgb(28, 45, 76) 0%,
    rgb(10, 10, 28) 55%,
    rgb(47, 24, 75) 100%
  );
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#BoxDescubra .title-n1 {
  font-size: 28px;
  line-height: 42px;
}

#Footer {
  background-color: #23204e;
  padding-top: 97px;
}
#Footer .logo {
  margin: 0 auto 56px;
}
#Footer .multi-contact {
  display: flex;
  justify-content: center;
  padding-bottom: 130px;
}
#Footer .multi-contact .social {
  text-align: center;
  margin: 0 25px;
  color: #fff;
  font-size: 18px;
}
#Footer .multi-contact .social figure {
  margin-bottom: 19px;
}
#Footer .copyright {
  background: rgb(25, 39, 66);
  background: linear-gradient(90deg, rgb(25, 39, 66) 0%, rgb(10, 10, 28) 100%);
  font-size: 14px;
  line-height: 125px;
  height: 125px;
  text-align: center;
}

@media (max-width: 640px) {
  .container {
    padding: 0 20px;
  }
  .btn {
    display: block;
    padding: 0px;
    width: 100%;
    text-align: center;
  }
  .title-n1 {
    font-size: 30px;
    line-height: 36px;
  }
  .logo {
    margin: 0 auto 20px;
    width: 250px;
    height: 81px;
    background-size: cover;
  }
  #Header {
    padding-bottom: 53px;
    padding-top: 43px;
  }
  #Header h2 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 19px;
  }
  #Header h3 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 22px;
  }
  #Header br {
    display: none;
  }
  #ComoFunciona {
    padding: 45px 0 60px 0;
  }
  #ComoFunciona header {
    padding-bottom: 17px;
  }
  #ComoFunciona header p {
    margin-bottom: 17px;
  }
  #ComoFunciona header p br {
    display: none;
  }
  #ComoFunciona .columns {
    display: block;
    margin-bottom: 15px;
  }
  #ComoFunciona .columns .box {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.1);
    max-width: 400px;
    width: 100%;
    border-radius: 12px;
    padding: 40px 15px;
    margin-bottom: 20px;
  }
  #ComoFunciona .columns .fnt-russo {
    height: auto;
    font-size: 23px;
    margin-bottom: 10px;
    display: block;
  }
  #ComoFunciona .columns p {
    font-size: 18px;
    line-height: 27px;
  }
  #ComoFunciona h4 {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 40px;
  }
  #ContainerGames {
    padding: 60px 0 30px 0;
  }
  #ContainerGames header {
    text-align: center;
    padding-bottom: 50px;
  }
  #ContainerGames .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  #ContainerGames .container .box {
    height: auto;
    background-color: transparent;
    width: 100%;
    margin-bottom: 40px;
    padding: 0;
    display: block;
  }
  #ContainerGames .container .box img {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
  #ContainerGames .container .box .info {
    padding: 0;
  }
  #ContainerGames .container .box .info .fnt-russo {
    line-height: 34px;
    margin-bottom: 14px;
    font-size: 20px;
  }
  #ContainerGames .container .box .info.last {
    display: block;
    text-align: center;
  }
  #ContainerGames .container .box .info.last .title-n1 {
    font-size: 30px;
    line-height: 36px;
  }
  #JogarComAmigos {
    background: url(../img/jogar-com-amigos-res.png) top right no-repeat;
    background-size: cover;
    height: auto;
  }
  #JogarComAmigos .container {
    padding-top: 60px;
  }
  #JogarComAmigos br {
    display: none;
  }
  #JogarComAmigos .title-n1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  #JogarComAmigos p {
    margin-bottom: 36px;
  }
  #CriarTorneio {
    background: rgb(50, 66, 94);
    background: linear-gradient(
      90deg,
      rgb(50, 66, 94) 0%,
      rgb(54, 57, 93) 31%,
      rgb(67, 47, 93) 61%,
      rgb(67, 46, 93) 99%
    );
    padding: 40px 0 59px;
  }
  #CriarTorneio .container {
    background-position: top center;
    padding-top: 431px;
  }
  #CriarTorneio .info {
    padding: 0;
  }
  #CriarTorneio br {
    display: none;
  }
  #CriarTorneio .title-n1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  #CriarTorneio p {
    margin-bottom: 36px;
  }
  #SectionMatchcoins {
    background-position: center top;
    height: auto;
  }
  #SectionMatchcoins::after {
    background: linear-gradient(
      to top,
      rgb(21, 19, 58) 20%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  #SectionMatchcoins br {
    display: none;
  }
  #SectionMatchcoins .container {
    padding-top: 104px;
  }
  #SectionMatchcoins br {
    display: none;
  }
  #SectionMatchcoins .title-n1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  #SectionMatchcoins p {
    margin-bottom: 36px;
  }
  #BoxDescubra {
    height: auto;
    background: rgb(50, 66, 94);
    background: linear-gradient(
      90deg,
      rgb(50, 66, 94) 0%,
      rgb(67, 47, 93) 100%
    );
  }
  #BoxDescubra br {
    display: none;
  }
  #BoxDescubra .title-n1 {
    font-size: 24px;
    line-height: 36px;
    padding: 52px 20px 68px;
  }
  #Footer {
    background-color: #23204e;
    padding-top: 58px;
  }
  #Footer .logo {
    margin: 0 auto 56px;
  }
  #Footer .multi-contact {
    display: block;
    justify-content: initial;
    padding-bottom: 30px;
  }
  #Footer .multi-contact .social {
    float: left;
    margin: 0 0 30px;
    width: 49%;
  }
  #Footer .multi-contact .social figure {
    margin-bottom: 19px;
  }
  #Footer .copyright {
    background: rgb(25, 39, 66);
    background: linear-gradient(
      90deg,
      rgb(25, 39, 66) 0%,
      rgb(10, 10, 28) 100%
    );
    font-size: 14px;
    line-height: 125px;
    height: 125px;
    text-align: center;
  }
}
